/*

    Name:       yoncé
    Author:     Thomas MacLean (http://github.com/thomasmaclean)

    Original yoncé color scheme by Mina Markham (https://github.com/minamarkham)

*/

.cm-s-yonce.CodeMirror { background: #1C1C1C; color: #d4d4d4; } /**/
.cm-s-yonce div.CodeMirror-selected { background: rgba(252, 69, 133, 0.478); } /**/
.cm-s-yonce .CodeMirror-selectedtext,
.cm-s-yonce .CodeMirror-selected,
.cm-s-yonce .CodeMirror-line::selection,
.cm-s-yonce .CodeMirror-line > span::selection,
.cm-s-yonce .CodeMirror-line > span > span::selection,
.cm-s-yonce .CodeMirror-line::-moz-selection,
.cm-s-yonce .CodeMirror-line > span::-moz-selection,
.cm-s-yonce .CodeMirror-line > span > span::-moz-selection { background: rgba(252, 67, 132, 0.47); }

.cm-s-yonce.CodeMirror pre { padding-left: 0px; }
.cm-s-yonce .CodeMirror-gutters {background: #1C1C1C; border-right: 0px;}
.cm-s-yonce .CodeMirror-linenumber {color: #777777;  padding-right: 10px; }
.cm-s-yonce .CodeMirror-activeline .CodeMirror-linenumber.CodeMirror-gutter-elt { background: #1C1C1C; color: #fc4384; }
.cm-s-yonce .CodeMirror-linenumber { color: #777; }
.cm-s-yonce .CodeMirror-cursor { border-left: 2px solid #FC4384; }
.cm-s-yonce .cm-searching { background: rgba(243, 155, 53, .3) !important; outline: 1px solid #F39B35; }
.cm-s-yonce .cm-searching.CodeMirror-selectedtext { background: rgba(243, 155, 53, .7) !important; color: white; }

.cm-s-yonce .cm-keyword { color: #00A7AA; } /**/
.cm-s-yonce .cm-atom { color: #F39B35; }
.cm-s-yonce .cm-number, .cm-s-yonce span.cm-type { color:  #A06FCA; } /**/
.cm-s-yonce .cm-def { color: #98E342; }
.cm-s-yonce .cm-property,
.cm-s-yonce span.cm-variable { color: #D4D4D4; font-style: italic; }
.cm-s-yonce span.cm-variable-2 { color: #da7dae; font-style: italic; }
.cm-s-yonce span.cm-variable-3 { color: #A06FCA; }
.cm-s-yonce .cm-type.cm-def { color: #FC4384; font-style: normal; text-decoration: underline; }
.cm-s-yonce .cm-property.cm-def { color: #FC4384; font-style: normal; }
.cm-s-yonce .cm-callee { color: #FC4384; font-style: normal; }
.cm-s-yonce .cm-operator { color: #FC4384; } /**/
.cm-s-yonce .cm-qualifier,
.cm-s-yonce .cm-tag { color: #FC4384; }
.cm-s-yonce .cm-tag.cm-bracket { color: #D4D4D4; }
.cm-s-yonce .cm-attribute { color: #A06FCA; }
.cm-s-yonce .cm-comment { color:#696d70; font-style:italic; font-weight:normal; } /**/
.cm-s-yonce .cm-comment.cm-tag { color: #FC4384 }
.cm-s-yonce .cm-comment.cm-attribute { color: #D4D4D4; }
.cm-s-yonce .cm-string { color:#E6DB74; } /**/
.cm-s-yonce .cm-string-2 { color:#F39B35; } /*?*/
.cm-s-yonce .cm-meta { color: #D4D4D4; background: inherit; }
.cm-s-yonce .cm-builtin { color: #FC4384; } /*?*/
.cm-s-yonce .cm-header { color: #da7dae; }
.cm-s-yonce .cm-hr { color: #98E342; }
.cm-s-yonce .cm-link { color:#696d70; font-style:italic; text-decoration:none; } /**/
.cm-s-yonce .cm-error { border-bottom: 1px solid #C42412; }

.cm-s-yonce .CodeMirror-activeline-background { background: #272727; }
.cm-s-yonce .CodeMirror-matchingbracket { outline:1px solid grey; color:#D4D4D4 !important; }
